import React from "react"
import Layout from "../layout"
import SEO from "../components/seo"
import Section from "../components/Section"
import Img from "gatsby-image"
import scrollTo from "gatsby-plugin-smoothscroll"
import BackgroundImage from "gatsby-background-image"

import { BsChevronDown } from "react-icons/bs"

import JoinListForm from "../components/JoinList"

import FanclubMomentsIcon from "../images/fanclub-moments.svg"
import EventTicketsIcon from "../images/event-tickets.svg"
import MusicIcon from "../images/music.svg"
import FanArtsIcon from "../images/fan-arts.svg"
import MerchandiseIcon from "../images/merchandise.svg"

const nftServices = [
  { icon: FanclubMomentsIcon, text: "FanClub moments" },
  { icon: EventTicketsIcon, text: "Event tickets" },
  { icon: MusicIcon, text: "Music" },
  { icon: FanArtsIcon, text: "Fan Arts" },
  { icon: MerchandiseIcon, text: "Merchandise" },
]

const NFT = ({ data, location }) => {
  const imageData = data.heroBanner.childImageSharp.fluid

  const sources = [
    { aspectRatio: 1, src: "", srcSet: "" },
    { ...imageData, media: "(min-width: 200px)" },
  ]

  const waitlistFormBgData = data.WaitlistFormBg.childImageSharp.fluid

  const waitlistFormSources = [
    { aspectRatio: 1, src: "", srcSet: "" },
    { ...waitlistFormBgData, media: "(min-width: 200px)" },
  ]

  const BackgroundWaveBg = data.BackgroundWaveBg.childImageSharp.fluid

  const BackgroundWaveBgSources = [
    { aspectRatio: 1, src: "", srcSet: "" },
    { ...BackgroundWaveBg, media: "(min-width: 200px)" },
  ]

  return (
    <Layout pageId="fanclub-nft" location={location}>
      <SEO title="Fanclub NFT's" />
      <Section id="nft" backgroundImage={sources}>
        <h1 className="fs-36 fs-lg-72 fs-xl-72 text-center font-bold ff-secondary text-gradient my-16">
          FanClub NFT's
        </h1>
        <button
          className="px-12 py-3 btn btn-white block mx-auto"
          onClick={() => scrollTo(".section--join-waitlist")}
        >
          <span className="font-normal fs-16 fs-lg-20 fs-xl-20 text-primary">
            Signup for waiting list
          </span>
        </button>
        {/* <a
          className="fs-16 fs-lg-20 fs-xl-24 px-10 py-4 btn mx-auto my-3 md:my-6"
          href="https://apps.apple.com/us/app/fanclub/id1535897256"
          target="_blank"
          rel="noopener noreferrer"
          style={{ boxShadow: "0px 1px 31px rgba(160, 70, 224, 0.4)" }}
        >
          Download the app now!
        </a> */}
        <span className="fs-16 fs-lg-20 fs-xl-24 mx-auto my-3 md:my-6 flex items-center cursor-pointer" onClick={() => scrollTo(".section--nft-service")}>
          Learn More <BsChevronDown className="inline-block ml-2" />
        </span>
      </Section>

      <Section id="nft-service">
        <h1
          id="nft-service"
          className="text-secondary fs-28 fs-lg-48 fs-xl-48 text-center font-bold ff-secondary"
        >
          FanClub NFT Service
        </h1>
        <div className="flex-none md:flex flex-col md:flex-row justify-center items-center mt-10 lg:mt-32">
          <div className="flex-none md:flex-1">
            <Img
              fluid={data.NFTService.childImageSharp.fluid}
              alt="How app work ?"
              className="w-full md:w-10/12"
            />
          </div>
          <div className="flex-none md:flex-1 text-fourth mt-10 lg:mt-0">
            <p className="w-full md:w-9/12 text-justify">
              The FanClub NFT service allows content creators to create, upload,
              store, and sell their own digital memorabilia for their fans to
              enjoy!
            </p>
            <ul className="mt-10">
              {nftServices.map((service, index) => (
                <li key={index} className="mb-6">
                  <img
                    className="inline"
                    src={service.icon}
                    alt={service.text}
                  />
                  <span className="ml-4">{service.text}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Section>

      <Section id="nft-artist" backgroundImage={BackgroundWaveBgSources}>
        <div className="container mx-auto pb-0 pt-0 px-8 flex flex-col h-auto">
          <h2 className="fs-24 fs-lg-38 fs-xl-38 font-bold text-center my-6 ff-secondary text-primary">
            In-house <span style={{ color: "#F4E900" }}>NFT artist</span> to
            collaborate and create exclusive digital art
          </h2>
          <p className="fs-28 text-fourth text-center mx-auto w-11/12 md:w-8/12">
            Creators and fans can showoff these NFTs on display in the{" "}
            <span className="text-gradient" style={{ fontWeight: 900 }}>
              FanClub NFT Marketplace
            </span>
          </p>
        </div>
        <div className="container mx-auto pb-0 pt-0 px-8 flex flex-col items-center h-auto">
          <div className="card flex-none md:flex flex-col md:flex-row justify-center items-center mt-40 relative">
            <div className="flex-none md:flex-1">
              <div
                className="fs-32 fw-600 font-bold ff-primary"
                style={{ maxWidth: 477 }}
              >
                Create, Discover, Collect, Sell your own{" "}
                <span className="text-gradient">NFT</span>
              </div>
            </div>
            <div className="hidden md:block flex-none md:flex-1">
              <div className="flex-none md:flex-1">
                <Img
                  fluid={data.NFTBannerBg.childImageSharp.fluid}
                  alt="How app work ?"
                  className="w-full card__image"
                  style={{ position: "absolute" }}
                />
              </div>
            </div>
          </div>
        </div>
      </Section>

      <Section id="join-waitlist">
        <BackgroundImage
          Tag={"div"}
          fluid={waitlistFormSources}
          style={{ padding: "150px 10px 124px 10px" }}
        >
          <div className="container flex flex-col justify-center items-center pt-8">
            <div className="sm:w-11/12 md:w-3/4">
              <h1 className="fs-26 fs-lg-42 fs-xl-56 text-center font-bold ff-secondary text-gradient my-2">
                Sign up for the waitlist to join
              </h1>
            </div>
            <div className="flex flex-col justify-center items-center mt-8" style={{ width: "calc(100% - 2rem)", maxWidth: 420}}>
              <JoinListForm />
            </div>
          </div>
        </BackgroundImage>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query {
    heroBanner: file(relativePath: { eq: "nft-hero-banner.png" }) {
      childImageSharp {
        fluid(maxWidth: 3080, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    NFTService: file(relativePath: { eq: "nft-service.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    NFTBannerBg: file(relativePath: { eq: "nft-banner-bg.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    WaitlistFormBg: file(relativePath: { eq: "waitlist-form-bg.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    BackgroundWaveBg: file(relativePath: { eq: "background-wave-bg.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default NFT
